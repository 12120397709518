// Here you can add other styles

body {
  font-size: 12px;
}
.sidebar-logo-in {
  color: white;
  font-size: 20px;
}

.sidebar-logo-out {
  color: white;
  font-size: 30px;
}

strong.roles-view {
  float: right;
  color: green;
}

.drop-heading {
  color: rgb(51 152 255);
  font-weight: 600;
}

.drop-details {
  color: green;
}

.expandable-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.expandable-th {
  padding: 10px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

tr.expanded {
  background-color: lightgray;
  transition: background-color 0.3s ease;
}

tr.expanded:hover {
  background-color: darkgray;
}

tr.expanded + tr {
  height: 0;
  overflow: hidden;
  transition: height 0.1s ease;
}

tr.expanded + tr .details {
  padding: 10px;
  white-space: normal;
  overflow: auto;
}

.pull-right {
  float: right;
}

.pull-right-inputgroup {
  float: right;
  width: 20% !important;
}

.notes-card {
  background-color: #e1e1e1;
  border-radius: 10px;
}
.link {
  cursor: pointer;
}
